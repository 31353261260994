.App {
  text-align: center;
  height:80em;
  display: flex;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes title-animation {
  from {
    opacity: 0;
   
  }

  to {
    opacity: 1;
    
  }
}
.display-4{
  color: whitesmoke;
  animation-name: title-animation;
  animation-duration: 2s;
  position: relative;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#page{
  
  background-color: #305561;
  background-repeat: no-repeat;
  padding-top: 0em;
  text-align: center;
  z-index: 1;
  width:100%;
}

#Nav-bar{
background-color: #3E3E3EBF;

width:100%;
z-index: 400;
top: 0px;
position:sticky;
color:whitesmoke; 
padding:0;
}
#Nav-bar #Root-List-Items{
  display: inline-flex;
  margin:0;
  position:sticky;
  padding:0;
}
#Nav-bar form{
  display: inline-flex;
}
#Nav-bar form input{
background-color: #2e2e2e63;
color: #f4fdff;
padding: 0%;
margin: 0%;
margin: 0 0.25em;

}
#Nav-bar #button{
  background-color: #273b42;
  width: 70%;
  cursor: pointer;
  padding: 0.2em 1em;
  margin: 0.2em;
  border-style: inset inset solid inset;
  border-color:#6b8189;
  border-width: 0.15em;
}
#Nav-bar #button:hover{
  border-color: #18262b6b;
  background-color: #18262ba5;
}

#Nav-bar #Root-List-Items li{
  height:2.5em;
  list-style: none;
  color:whitesmoke;
  padding: .5em 1em .5em 1em;
 


}
.Dropdown{
  display:block;
  white-space: nowrap;
  
}
.Dropdown:hover ul{
  display:block;
}
.Dropdown ul{
  display:none;
  margin: 0 -1em;
  width: fit-content;
  padding: 0;
  top:2.5em;
  background-color: #3E3E3EBF;
  position:absolute;

}

#Nav-bar li:hover{
  background-color: #2E2E2E;
  cursor: pointer;
}
div a{ color:white;}
div a:active{ color:white;}
div a:visited{ color:white;}

#webContent{
  color:whitesmoke;
  margin:auto;
  height:84%;
  border-radius: 0.2em;
  margin-top: 2em;
  display: block;
  width: 100%;
  padding: 0px;
  background-color: #f4fdff;
}

.generic-button{
  color:white;
  background-color: #273b42;
  padding: 0.2em 1em;
  margin-left:1em;
  border-style: inset inset solid inset;
  border-color:#6b8189;
  border-width: 0.15em;
}
.generic-button:hover{
  background-color: #2E2E2E;
  cursor: pointer;
}

#post-widget{
  display: inline-flexs;
  text-align: left;
  width:100%;
  height:fit-content;
}
#post-widget input{
  grid-column-start: 1;
  width:40%;
  height:3em;
  resize:none;
  margin:0.5em;

}
#post-widget .generic-button{
  margin: 0.5em;
  text-align: center;
  float:inline-end;
  padding:0.5em;
  height: 3em;
}
#note-mapper{ 

  flex-direction: column;
  width: 100%;
  height:50%;
  position: relative;
  text-align: center;
}
#note-mapper .list-item{
  position:absolute;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  text-align: left;
  white-space:pre-wrap;
  max-width: 15em;
  border-radius: 3%;
  color:whitesmoke;
  background-color: #487e9182;
  max-height: 100em;
  border-radius: 5px 5px 0 0;
}
.list-item p{
  padding: 0.25em 0.5em 0 0.5em;
  margin-bottom:0.25em;
}
#note-menu{
  width: 100%;
  height: 10%;
  display:inline-flex;
  text-align: center;
  justify-content: space-between;
  border-color: rgb(111, 112, 115);
  border-style: solid;
  border-width: 0 0 1px 0;
  border-radius: 5px 5px 0 0;
  background-color: rgb(155, 158, 164);
}
#note-menu div{
  padding: 0 4%;
  max-width: 1.7em;
  border-width: 1px;

  border-color: black rgb(96, 93, 93);
  border-style: none solid solid none;
}
#note-menu .menu-icon{
  border-width: 1px;
  border-color:black rgb(96, 93, 93);
  border-style: none solid solid solid;
}
.menu-icon{
  overflow: visible;
}
#note-menu .menu-icon:hover{
  background-color: rgb(87, 87, 89);
}
#note-menu .NoteMenu{
  position:absolute;
  background-color:rgb(155, 158, 164);
  max-width:20em;
}
.NoteMenu:hover{
  background-color: rgb(87, 87, 89);

}
/*Style for Wordle page:
- wordle body holds guess list
- guess item is the guessed item
- keyboard spans the page and displays keyboard buttons
*/
#wordle-body{
  margin:auto;
  width: 50%;
  min-width: fit-content;
  height:fit-content;
  border-radius: 0.2em;
  margin-top: 2em;
  display: grid;
  grid-template-columns: repeat(7,1fr);
  grid-template-rows: repeat(5,1fr);
  padding:1em 5%;
  background-color: #fafefe; 
  gap: 3px;

}
.guess-item{

  background-color: inherit;
  border-style: solid;
  border-color: #282c34;
  border-width: 2px;
  width: 1.75em;
  margin:0.08em 0;
  font-size:calc(12px + 3.5vw);
  border-radius: 0.05em;
  height: 1.75em;
  min-height: 0.2em;
}
#keyboard{
  display: flex;
  
  flex-wrap: wrap;
  width: fit-content;
  padding:calc(2px);
  justify-content: center;
}
#keyboard .keyboard-button{
  background-color: #fafefe;
  border-style: solid;
  border-color: #282c34;
  border-width: 1px;
  max-width: 9em;
  min-width: 1em;
  text-overflow: hidden;
  white-space: nowrap;
  margin:0.1em;
  font-size: calc(22px + 1.5vw);
  border-radius: 0.05em;
  height: 1.2em;
  min-height: 0.5em;
  padding: 0 0.6em 1.5em 0.5em;
  padding-bottom: 1.5em;
  position:relative;
}
#keyboard .keyboard-button:hover{
  background-color: wheat;
}
.keyboard26 p{
  display: none;
}
.keyboard26:after{
  content: 'Enter↵'
}
.keyboard27 p{
  display: none;
}
.keyboard27:after{
  content:'⌫';
}
#resume-container{
  width: 100%;
  height:100%;
  text-align: left;
}


.thumbnail-resume{
  position:absolute;
  transform-origin: top;
}
#about-container{
  color:whitesmoke;

}
@keyframes aboutanimation {
  0%  {top:-100px; opacity: 0}
  100%  {top:0px; opacity:1}
}
#about-container p{
  padding:0 15%;
  animation-name: aboutanimation;
  animation-duration: 1s;
  position: relative;
}
#about-container img{
  position: relative;
  animation-delay: -0.1s;
  border-radius: 50%;
  border-color:transparent;
  width:15%;
  margin: 30px 0;
  animation-name: aboutanimation;
  animation-duration: 1.5s;
}
#resume-png{
  position: absolute;
  max-width: 100%;
}
#home-canvas{
  display: inline-flex;
  flex-wrap:wrap;
  width: 100%;
  height:100%;
  padding:0 10%;
  line-height: 1em;
}
#home-canvas p {
  display: block;
  margin:0 1px;
  width: 8px;
  position: absolute;
  
  height:10px;
}
